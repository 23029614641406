<template>
    <div v-if="isOpen" class="modal-wrapper">
      <!-- Background overlay -->
      <div class="background-overlay"></div>
  
      <!-- Modal container -->
      <div class="modal-container">
        <h2 class="title">{{ isEditMode ? 'Edit Content' : 'Add New Content' }}</h2>
        <form @submit.prevent="submitForm">
          <div class="form-row">
            <!-- Content Name Label and Input -->
            <div class="form-group">
              <label for="contentName" class="form-label">Name</label>
              <input
                type="text"
                id="contentName"
                v-model="contentName"
                class="form-input"
              />
            </div>
  
            <!-- Content Type Label and Select -->
            <div class="form-group">
              <label for="contentType" class="form-label">Type</label>
              <select id="contentType" v-model="contentType" class="form-input">
                <option
                  v-for="type in contentTypes"
                  :key="type.value"
                  :value="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
  
          <!-- Conditional Inputs Based on Content Type -->
          <div v-if="contentType === 'email_template'" class="mb-4">
  <label for="emailSubject" class="form-label">Subject</label>
  <input
    type="text"
    id="emailSubject"
    v-model="emailSubject"
    class="form-input"
    placeholder="Enter your email subject"
  />

            <label class="pt-2 form-label">Body</label>
            <textarea
              v-model="emailTemplate"
              class="form-input"
              rows="4"
              placeholder="Write your email template here..."
            ></textarea>
  
            <!-- Parameter Buttons -->
            <div class="parameter-buttons mt-2">
              <button
                type="button"
                class="param-button"
                @click="insertText('recipient_first')"
              >
                Recipient First
              </button>
              <button
                type="button"
                class="param-button"
                @click="insertText('agency_name')"
              >
                Agency Name
              </button>
              <button
                type="button"
                class="param-button"
                @click="insertText('agent_name')"
              >
                Producer Name
              </button>
              <button
                type="button"
                class="param-button"
                @click="insertText('agent_title')"
              >
                Email Address
              </button>
              <button
                type="button"
                class="param-button"
                @click="insertText('agency_number')"
              >
                Agency Phone #
              </button>
            </div>
          </div>

          <div v-if="contentType === 'library'" class="mb-4">
            <label for="libraryDropdown" class="form-label">Select a Document</label>
          <select id="libraryDropdown" v-model="selectedLibraryDoc" class="form-input">
            <option value="">Select a document...</option>
            <option
              v-for="(libDoc, index) in libraryDocuments"
              :key="index"
              :value="libDoc.id"
            >
              {{ libDoc.name }}
            </option>
          </select>
          </div>
  
          <!-- File Upload for non-email content -->
          <div v-if="contentType && contentType != 'email_template' && contentType != 'library'" class="mb-4">
            <label class="form-label">Upload File</label>
            <input type="file" @change="handleFileUpload" class="form-input" />
          </div>
  
          <!-- Categories Checkboxes (Horizontal Layout) -->
          <div class="mb-4">
            <label class="form-label">Categories</label>
            <div class="checkbox-container">
              <div
                v-for="category in categories"
                :key="category.id"
                class="checkbox-item"
              >
                <label>
                  <input
                    type="checkbox"
                    v-model="selectedCategories"
                    :value="category.id"
                  />
                  {{ convertTitle(category.name) }}
                </label>
              </div>
            </div>
          </div>
  
          <!-- Buttons -->
          <div class="button-container">
            <button type="button" @click="closeModal" class="cancel-button">
              Cancel
            </button>
            <button type="submit" class="save-button">
              {{ isEditMode ? 'Update' : 'Save' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      isOpen: {
        type: Boolean,
        default: true,
      },
      selectedContent: {
        type: Object,
        default: null, // Content passed when editing
      },
    },
    data() {
      return {
        selectedLibraryDoc: [],
        libraryDocuments: [],
        categories: [
          { id: 1, name: 'IQ & EQ Sales Tactics' },
          { id: 2, name: 'Influencers Key Strategy' },
          { id: 3, name: 'Friend & Relationship' },
          { id: 4, name: 'Authority & Expertise' },
          { id: 5, name: 'Consistency & Behavioral Patterns' },
          { id: 6, name: 'Reciprocity' },
          { id: 7, name: 'Compare, Contrast Secrets' },
          { id: 8, name: 'Future Vision Creation' },
          { id: 9, name: 'A Real Closing Strategy' },
        ],
        contentId: null,
        contentName: '',
        contentType: '',
        emailSubject: '',
        emailTemplate: '',
        selectedFile: null,
        selectedCategories: [],
        contentTypes: [],
        isEditMode: false, // To toggle between edit and add mode
      };
    },
    watch: {
      selectedContent: {
        immediate: true,
        handler(newContent) {
          if (newContent) {
            this.setEditMode(newContent); // Set edit mode if content is passed
          } else {
            this.clearForm(); // Clear form if no content is passed
          }
        },
      },
    },
    methods: {
      convertTitle(title) {
      const titleMap = {
        "A Real Closing Strategy": "A Compelling Reason to Close",
        "Influencers Key Strategy": "Decision Makers/Influencers",
        // Add more mappings as needed
      };
      return titleMap[title] || title; // Default to original title if no match
    },
      fetchContentTypes() {
        this.contentTypes = [
        { value: 'library', text: "Document Library"},
          { value: 'email_template', text: 'Email' },
          { value: 'document', text: 'Document' },
          { value: 'video', text: 'Video' },
          { value: 'audio', text: 'Audio' },
        ];
      },
      fetchCategories() {
        this.categories = [
          { id: 1, name: 'IQ & EQ Sales Tactics' },
          { id: 2, name: 'Influencers Key Strategy' },
          { id: 3, name: 'Friend & Relationship' },
          { id: 4, name: 'Authority & Expertise' },
          { id: 5, name: 'Consistency & Behavioral Patterns' },
          { id: 6, name: 'Reciprocity' },
          { id: 7, name: 'Compare, Contrast Secrets' },
          { id: 8, name: 'Future Vision Creation' },
          { id: 9, name: 'A Real Closing Strategy' },
        ];
      },
      insertText(param) {
        this.emailTemplate += `[${param}]`;
      },
      handleFileUpload(event) {
        this.selectedFile = event.target.files[0];
      },
      // Parsing the subject from the content on edit
      setEditMode(content) {
        console.log('content: ', content);
        this.isEditMode = true;
        this.contentId = content.id; 
        this.contentName = content.name;
        this.contentType = content.type;
  
        // Parse the subject from the content
        const subjectMatch = content.content.match(/^Subject:\s*(.*)\s*$/m);
        if (subjectMatch) {
          this.emailSubject = subjectMatch[1]; // Extract the subject
        }
  
        // Remove the subject line from the email body
        this.emailTemplate = content.content.replace(/^Subject:\s*(.*)\s*$/m, '').trim();
  
        if (content.categories && content.categories.length > 0) {
          const selectedCategoryNames = content.categories;
  
          this.selectedCategories = this.categories
            .filter(c => selectedCategoryNames.includes(c.name))  // Compare names directly
            .map(c => c.id);
          
          console.log('Selected categories IDs: ', this.selectedCategories);
        } else {
          this.selectedCategories = [];
        }
      },

      async fetchLibraryDocuments() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/agency-documents`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log("returned documents: ", response.data.documents);
        console.log("returned sharedDocuments: ", response.data.sharedDocuments);

        // Map regular documents (UploadedDocument)
        const regularDocs = response.data.documents.map((doc) => ({
          id: doc.id, // Document ID
          name: doc.original_file_name, // Original file name
          objectType: 'document',
        }));

        // Map shared documents (SalesObject) that are whitepapers and shared
        const sharedDocs = response.data.sharedDocuments.map((doc) => ({
          id: doc.id, // Shared SalesObject ID
          name: doc.name, // Name from SalesObject
          objectType: 'shared', // Flag for UI purposes
        }));

        // Combine both arrays into one
        this.libraryDocuments = [...regularDocs, ...sharedDocs];

        console.log("Fetched library documents:", this.libraryDocuments);
      } catch (error) {
        console.error("Error fetching library documents:", error);
        this.libraryDocuments = []; // Clear library on error
        alert("Failed to fetch library documents.");
      }
    },
  
      // When saving, combine the subject and email template into the desired format
      async submitForm() {
        const formData = new FormData();
  
        // Append form data
        formData.append('name', this.contentName);
        formData.append('type', this.contentType);
  
        // Append categories as an array
        const selectedCategoryNames = this.selectedCategories.map(categoryId => {
          const category = this.categories.find(c => c.id === categoryId);
          return category ? category.name : null; // Find category name by ID
        }).filter(name => name !== null); // Filter out null values (if any)
  
        selectedCategoryNames.forEach(categoryName => {
          formData.append('categories[]', categoryName);  // Correct array format for names
        });
  
        // Combine the subject and email body
        const combinedContent = `Subject: ${this.emailSubject}\n${this.emailTemplate}`;
        formData.append('content', combinedContent);
  
        // Append the file if it's available
        if (this.selectedFile) {
          formData.append('uploadFile', this.selectedFile); 
        }
  
        // If editing, append the content ID for updates
        if (this.isEditMode && this.contentId) {
          formData.append('id', this.contentId);
        }
  
        // If the content type is "library", append the selected library document details.
        if (this.contentType === "library") {
          // Assuming selectedLibraryDoc holds the id.
          formData.append('document_id', this.selectedLibraryDoc);
          // Retrieve the full document object from libraryDocuments.
          const selectedDoc = this.libraryDocuments.find(doc => doc.id === this.selectedLibraryDoc);
          if (selectedDoc) {
            formData.append('objectType', selectedDoc.objectType);
          }
        }

        console.log("Form Data: ", formData);
  
        try {
          const url = `${process.env.VUE_APP_API_BASE_URL}/save-object`;
  
          const response = await axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
  
          // Handle success
          if (response.data.success) {
            alert(this.isEditMode ? 'Content updated successfully!' : 'Content saved successfully!');
            this.closeModal(response.data.object); // Emit the saved or updated object
            location.reload();
          } else {
            alert('Failed to save content: ' + response.data.message);
          }
        } catch (error) {
          console.error('Error saving content:', error);
          alert('An error occurred while saving the content.');
        }
      },
  
      clearForm() {
        console.log("CLEARING FORM");
        this.isEditMode = false;
        this.contentId = null;
        this.contentName = '';
        this.contentType = '';
        this.emailTemplate = '';
        this.emailSubject = '';
        this.selectedCategories = [];
        this.selectedFile = null;
      },
      closeModal(savedContent = null) {
        this.$emit('close', savedContent); // Emit saved or updated content
      },
    },
    mounted() {
      this.fetchContentTypes();
      this.fetchCategories();
      this.fetchLibraryDocuments();
    },
  };
  </script>
  
  
  <style scoped>
  /* Full-screen wrapper for the modal */
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2050;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Background overlay */
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
    z-index: 2049;
  }
  
  /* Modal container with scroll */
  .modal-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 50vw;
    width: 100%;
    max-height: 85vh;
    overflow-y: auto;
    z-index: 2051;
    position: relative;
    border: 2px solid #007bff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-group {
    width: 48%;
  }
  
  .form-label {
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .parameter-buttons {
    display: flex;
    gap: 10px;
  }
  
  .param-button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    color: #333;
  }
  
  .param-button:hover {
    background-color: #e2e8f0;
  }
  
  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .checkbox-item {
    margin-right: 10px;
    margin-bottom: 8px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .cancel-button,
  .save-button {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: white;
    margin-right: 10px;
  }
  
  .save-button {
    background-color: #007bff;
    color: white;
  }
  </style>
