<template>
  <div class="page-container">
    
        <!-- Custom Context Menu -->
        <div
          v-if="contextMenu.visible"
          :style="{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }"
          class="context-menu"
        >
          <button class="context-menu-item" @click="renameItem(contextMenu.target)">Rename</button>
          <button class="context-menu-item" @click="deleteItem(contextMenu.target)">Delete</button>
        </div>


    <!-- Library Index at the top -->
    <div class="library-index">
      <h2 class="index-header">File Management</h2>

      <div class="toolbar top-right">
        <div class="button-container">
  <button class="new-folder-button" @click="createNewFolder">
    <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="button-icon">
      <path d="M19.985 10.4268C19.985 7.78797 18.9145 5.39675 17.1854 3.6685C15.4563 1.94025 13.0659 0.868896 10.4271 0.868896C7.78821 0.868896 5.39699 1.93938 3.66874 3.6685C1.9405 5.39762 0.869141 7.78797 0.869141 10.4268C0.869141 13.0657 1.93963 15.4569 3.66874 17.1851C5.39786 18.9134 7.78821 19.9848 10.4271 19.9848C13.0659 19.9848 15.4571 18.9143 17.1854 17.1851C18.9136 15.456 19.985 13.0657 19.985 10.4268ZM18.2472 10.4268C18.2472 12.5869 17.3731 14.5402 15.9568 15.9565C14.5405 17.3728 12.5872 18.2469 10.4271 18.2469C8.26698 18.2469 6.31368 17.3728 4.89737 15.9565C3.48106 14.5402 2.60695 12.5869 2.60695 10.4268C2.60695 8.26673 3.48106 6.31344 4.89737 4.89713C6.31368 3.48082 8.26698 2.6067 10.4271 2.6067C12.5872 2.6067 14.5405 3.48082 15.9568 4.89713C17.3731 6.31344 18.2472 8.26673 18.2472 10.4268ZM6.95146 11.2957H9.55816V13.9024C9.55816 14.3821 9.94743 14.7713 10.4271 14.7713C10.9067 14.7713 11.296 14.3821 11.296 13.9024V11.2957H13.9027C14.3823 11.2957 14.7716 10.9065 14.7716 10.4268C14.7716 9.94719 14.3823 9.55792 13.9027 9.55792H11.296V6.95121C11.296 6.47158 10.9067 6.08231 10.4271 6.08231C9.94743 6.08231 9.55816 6.47158 9.55816 6.95121V9.55792H6.95146C6.47182 9.55792 6.08256 9.94719 6.08256 10.4268C6.08256 10.9065 6.47182 11.2957 6.95146 11.2957Z" fill="white"/>
    </svg>
    New Folder
  </button>

  <button class="upload-file-button" @click="triggerFileInput">
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3748 13.0313L17.3748 16.5063C17.3748 16.7461 17.2784 16.9624 17.1203 17.1205C16.9622 17.2786 16.7458 17.375 16.5061 17.375L4.34355 17.375C4.10378 17.375 3.88746 17.2786 3.72935 17.1205C3.57124 16.9624 3.4748 16.7461 3.4748 16.5063L3.4748 13.0313C3.4748 12.5517 3.0856 12.1625 2.60605 12.1625C2.1265 12.1625 1.7373 12.5517 1.7373 13.0313L1.7373 16.5063C1.7373 17.2256 2.03007 17.8789 2.50094 18.3489C2.9718 18.8189 3.62423 19.1125 4.34355 19.1125L16.5061 19.1125C17.2254 19.1125 17.8787 18.8198 18.3487 18.3489C18.8187 17.8781 19.1123 17.2256 19.1123 16.5063L19.1123 13.0313C19.1123 12.5517 18.7231 12.1625 18.2436 12.1625C17.764 12.1625 17.3748 12.5517 17.3748 13.0313ZM9.55605 4.70346L9.55606 13.0313C9.55606 13.5108 9.94526 13.9 10.4248 13.9C10.9044 13.9 11.2936 13.5108 11.2936 13.0313L11.2936 4.70346L14.1543 7.56425C14.494 7.90393 15.0439 7.90393 15.3828 7.56425C15.7216 7.22457 15.7224 6.67465 15.3828 6.33584L11.039 1.99209C10.9591 1.91217 10.8635 1.84788 10.7575 1.80357C10.443 1.67326 10.0669 1.73581 9.8106 1.99209L5.46685 6.33584C5.12717 6.67552 5.12717 7.22544 5.46685 7.56425C5.80653 7.90307 6.35645 7.90394 6.69526 7.56425L9.55605 4.70346Z" fill="white"/>
    </svg>
    Upload File
  </button>
</div>






          <input
            type="file"
            ref="fileInput"
            class="file-input"
            @change="handleFileUpload"
            hidden
          />

        <input
          type="text"
          v-model="searchQuery"
          class="search-bar"
          placeholder="Search..."
        />
      </div>
      <!-- File Grid Section -->
      <div class="file-grid">
        <!-- Recently Accessed Section -->
        <div class="recent-section">
          <h3>Recently Accessed</h3>
          <div class="recent-files">
            <div
              v-for="file in filteredFiles"
              :key="file.id"
              class="recent-file-card redesigned cursor-pointer"
              draggable="true"
              @dragstart="onDragStart(file)"
              @click="previewFile(file)"
              @contextmenu="showContextMenu($event, file)"
            >
              <div class="file-icon-wrapper">
                <i :class="getFileTypeIcon(file.type)" class="file-icon"></i>
              </div>
              <div class="file-details">
                <p class="file-name" :title="file.name">{{ file.name }}</p>
                <p class="file-date">{{ new Date(file.uploadedOn).toLocaleDateString() }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Folder Section -->
        <div class="folder-section">
          <h3>Folders</h3>
          <div class="folder-grid">
            <div
              class="folder-card redesigned cursor-pointer"
              v-for="folder in folders"
              :key="folder.id"
              @dragover.prevent
              @drop="onDrop(folder)"
              @dragenter="onDragEnter($event, folder)"
              @dragleave="onDragLeave($event, folder)"
              @click="openFolder(folder)"
              @contextmenu="showContextMenu($event, folder)"
            >
              <div class="folder-icon-wrapper">
                <i class="fa fa-folder folder-icon"></i>
              </div>
              <div class="folder-details">
                <p class="folder-name" :title="folder.name">{{ folder.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- File Management Section -->
    <div class="file-management">

      <div class="all-files">
        <h3>All Files</h3>
        <table class="file-table redesigned">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Added</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in filteredFiles" :key="file.id">
              <td>{{ file.name }}</td>
              <td>{{ file.type }}</td>
              <td>{{ new Date(file.uploadedOn).toLocaleDateString() }}</td>
              <td class="actions-column">
                <div class="dropdown">
                  <button class="dropdown-toggle2" @click.stop="toggleDropdown(file.id)">...</button>
                  <div
                    class="dropdown-menu2"
                    v-if="activeDropdown === file.id && !isFolderViewOpen"
                    @click.stop
                  >
                    <button @click="previewFile(file)" class="dropdown-item cursor-pointer">Preview</button>
                    <button @click="removeFile(file)" class="dropdown-item cursor-pointer">Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination
        :total="filteredFiles.length"
        v-model:currentPage="currentPage"
        :per-page="itemsPerPage"
      ></pagination>
    </div>
  </div>

<!-- Folder Modal -->
<div v-if="isFolderViewOpen" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; z-index: 1000;">
  <div
    class="modal-container"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
      width: 95vw; /* Ensures width is 95% of viewport width */
      max-width: 1400px; /* Maximum width for large screens */
      height: 85vh; /* Ensures height is 85% of viewport height */
      max-height: 90%; /* Prevents overflow */
      overflow-y: auto;
    "
  >
    <button
      class="close-modal-button"
      @click="closeFolderView"
      style="
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 1.5rem;
        color: #555555;
        cursor: pointer;
        font-weight: bold;
      "
      @mouseover="(e) => (e.currentTarget.style.color = '#FF6347')"
      @mouseout="(e) => (e.currentTarget.style.color = '#555555')"
    >
      ×
    </button>
    <h3
      class="modal-header"
      style="
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 30px;
        text-align: center;
        color: #333333;
      "
    >
      {{ currentFolder?.name }}
    </h3>

    <div
      class="file-grid"
      style="
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        gap: 20px;
        padding: 100px;
        width: 100%; /* Ensure grid takes full width */
        height: auto;
      "
    >
      <div
        v-for="file in filesInFolder"
        :key="file.id"
        class="file-card cursor-pointer"
        style="
          background: #f7f7f7;
          border: 1px solid #e0e0e0;
          border-radius: 10px;
          width: 240px;
          text-align: center;
          padding: 20px;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s ease;
        "
        @mouseover="(e) => (e.currentTarget.style.transform = 'scale(1.05)')"
        @mouseout="(e) => (e.currentTarget.style.transform = 'scale(1)')"
        @click="previewFile(file)"
      >
        <div
          style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px;"
        >
          <div
            style="
              overflow: hidden;
              width: calc(100% - 30px);
              height: 1.5rem;
              position: relative;
            "
          >
            <p
              class="file-name"
              :title="file.name"
              style="
                font-size: 1rem;
                font-weight: bold;
                color: #333333;
                white-space: nowrap;
                margin: 0;
                margin-top: -30px;
                position: absolute;
                transform: translateX(0);
                transition: transform 3s linear;
              "
              @mouseover="(e) => (e.currentTarget.style.transform = 'translateX(-50%)')"
              @mouseout="(e) => (e.currentTarget.style.transform = 'translateX(0)')"
            >
              {{ file.name }}
            </p>
          </div>
          <div class="dropdown" style="position: relative;">
            <button
              style="
                background: none;
                border: none;
                font-size: 1.2rem;
                cursor: pointer;
                color: #333333;
              "
              @click.stop="toggleDropdown(file.id)"
            >
              ...
            </button>
            <div
              v-if="activeDropdown === file.id"
              style="
                position: absolute;
                top: 100%;
                right: 0;
                background: #ffffff;
                border: 1px solid #e0e0e0;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                padding: 10px;
                z-index: 10;
                text-align: left;
              "
            >
              <button
                @click="previewFile(file)"
                style="
                  display: block;
                  padding: 5px 10px;
                  font-size: 0.85rem;
                  background: none;
                  border: none;
                  text-align: left;
                  color: #333333;
                  cursor: pointer;
                  width: 100%;
                "
                @mouseover="(e) => (e.currentTarget.style.color = '#007bff')"
                @mouseout="(e) => (e.currentTarget.style.color = '#333333')"
              >
                Preview
              </button>
              <!--button
                @click="downloadFile(file)"
                style="
                  display: block;
                  padding: 5px 10px;
                  font-size: 0.85rem;
                  background: none;
                  border: none;
                  text-align: left;
                  color: #333333;
                  cursor: pointer;
                  width: 100%;
                "
                @mouseover="(e) => (e.currentTarget.style.color = '#007bff')"
                @mouseout="(e) => (e.currentTarget.style.color = '#333333')"
              >
                Download
              </button-->
              <button
               v-if="!file.is_shared" 
                @click="removeFileFromFolder(file)"
                style="
                  display: block;
                  padding: 5px 10px;
                  font-size: 0.85rem;
                  background: none;
                  border: none;
                  text-align: left;
                  color: #333333;
                  cursor: pointer;
                  width: 100%;
                "
                @mouseover="(e) => (e.currentTarget.style.color = '#007bff')"
                @mouseout="(e) => (e.currentTarget.style.color = '#333333')"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
        <div
          class="file-icon-wrapper"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100px;
            background: #ffffff;
            border-radius: 50%;
            margin: 0 auto 15px;
            border: 1px solid #e0e0e0;
          "
        >
          <i :class="getFileTypeIcon(file.type)" class="file-icon" style="font-size: 2.5rem; color: #555555;"></i>
        </div>
      </div>
    </div>
  </div>
</div>



</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      files: [],
      folders: [],
      filesInFolder: [],
      draggedFile: null, // To store the file being dragged
      currentFolder: null, // Tracks the folder being viewed
      isFolderViewOpen: false, // Toggles the folder view
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 10,
      activeDropdown: null, // Tracks the currently active dropdown
      contextMenu: {
        visible: false,
        x: 0,
        y: 0,
        target: null, // Stores the target (file or folder) for the context menu
      },
    };
  },
  computed: {
    filteredFiles() {
      if (!this.searchQuery.trim()) {
        return this.files;
      }
      const query = this.searchQuery.toLowerCase();
      return this.files.filter(
        (file) =>
          file.name.toLowerCase().includes(query) ||
          file.type.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    showContextMenu(event, target) {
  event.preventDefault(); // Prevent the default right-click menu

  const mouseX = event.clientX;
  const mouseY = event.clientY;

  const menuWidth = 150; // Approximate menu width
  const menuHeight = 100; // Approximate menu height

  // Adjust positions to avoid overflow
  const x = (mouseX + menuWidth > window.innerWidth) ? (window.innerWidth - menuWidth) : mouseX;
  const y = (mouseY + menuHeight > window.innerHeight) ? (window.innerHeight - menuHeight) : mouseY;

  this.contextMenu = {
    visible: true,
    x,
    y,
    target,
  };
},

    hideContextMenu() {
      this.contextMenu.visible = false;
      this.contextMenu.target = null;
    },
    async renameItem(item) {
  this.hideContextMenu();
  
  // Prompt user for the new name
  const newName = prompt(`Rename "${item.name}" to:`, item.name);
  if (!newName || newName === item.name) return; // Exit if no change

  try {
    const accessToken = localStorage.getItem("access_token");
    let url;

    // Determine API endpoint based on the object type
    if (item.object_type === "folder") {
      url = `${process.env.VUE_APP_API_BASE_URL}/rename-folder/${item.id}`;
    } else {
      url = `${process.env.VUE_APP_API_BASE_URL}/rename-file/${item.id}`;
    }

    // Make the API request
    await axios.patch(
      url,
      { name: newName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Update the name locally
    item.name = newName;

    //alert(`Renamed successfully to "${newName}".`);
  } catch (error) {
    console.error("Error renaming item:", error);

    // Check if the response object exists and display error message
    if (error.response && error.response.data) {
      alert(`Error renaming item: ${error.response.data.message || error.message}`);
    } else {
      alert(`Error renaming item: ${error.message}`);
    }
  }
},

    deleteItem(item) {
      console.log("DELETE ITEM : " ,item);
      this.hideContextMenu();
      if (item.object_type === "folder") {
          this.deleteFolder(item);
        } else {
          this.removeFile(item);
        }
    },

    async openFolder(folder) {
      this.currentFolder = folder;
      this.isFolderViewOpen = true;
      console.log("OPENEING FOLDER :", folder);

      if (folder.id === -1) {
                // Map the files from the response to your desired format
                this.filesInFolder = folder.files.map((file) => ({
          id: file.id,
          name: file.name || "Unnamed File", // Check if `file.name` exists
          object_type: "file",
          is_shared: true
        }));
        return;
      }

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/folders/${folder.id}/files`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Log the response to debug the issue
        console.log("API Response:", response.data);

        // Map the files from the response to your desired format
        this.filesInFolder = response.data.files.map((file) => ({
          id: file.id,
          name: file.original_file_name || "Unnamed File", // Check if `file.name` exists
          object_type: "file",
          type: file.original_file_name
            ? file.original_file_name.split(".").pop().toUpperCase()
            : "UNKNOWN",
          uploadedOn: file.created_at || new Date().toISOString(),
        }));
      } catch (error) {
        console.error("Error fetching folder files:", error);
        alert("Failed to open folder.");
        this.isFolderViewOpen = false; // Close modal in case of an error
      }
    },



  // Method to go back to the main view
  closeFolderView() {
    this.isFolderViewOpen = false;
    this.currentFolder = null;
    this.fetchLibraryFiles(); // Refresh main file view
  },

    async handleFileUpload(event) {
      const uploadedFile = event.target.files[0];
      if (!uploadedFile) return;

      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("type", "library"); // Ensure the type matches the API requirements

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/upload-document`;

        await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        //alert("File uploaded successfully!");
        this.fetchLibraryFiles(); // Refresh the file list after upload
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file.");
      }
    },
    async fetchFolders() {
  try {
    const accessToken = localStorage.getItem("access_token");
    const url = `${process.env.VUE_APP_API_BASE_URL}/folders`;
    console.log("[fetchFolders] Fetching folders from:", url);
    console.log("[fetchFolders] Using access token:", accessToken);

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log("[fetchFolders] API response received:", response.data);

    // Map the regular folders
    this.folders = response.data.folders.map((folder) => {
      console.log("[fetchFolders] Mapping folder:", folder);
      return {
        id: folder.id,
        object_type: "folder",
        name: folder.name,
      };
    });
    console.log("[fetchFolders] Mapped folders:", this.folders);

    // Map the shared folder if it exists, then insert it as the first element
    if (response.data.sharedFolder) {
      console.log("[fetchFolders] Found shared folder in response:", response.data.sharedFolder);
      const sharedFolder = {
        id: response.data.sharedFolder.id,
        object_type: "folder",
        name: response.data.sharedFolder.name,
        // Map shared folder files (assumes each file object has id, name, type, and uploadedOn)
        files: response.data.sharedFolder.files.map((file) => {
          console.log("[fetchFolders] Mapping shared file:", file);
          return {
            id: file.id,
            object_type: file.object_type || "sales_object",
            name: file.name,
            type: file.type,
            uploadedOn: file.uploadedOn,
          };
        }),
      };
      console.log("[fetchFolders] Mapped shared folder:", sharedFolder);
      // Insert the shared folder as the first element of the folders array
      this.folders.unshift(sharedFolder);
      console.log("[fetchFolders] Updated folders with shared folder at the start:", this.folders);
    } else {
      console.log("[fetchFolders] No shared folder found in API response.");
    }
  } catch (error) {
    console.error("[fetchFolders] Error fetching folders:", error);
    alert("Failed to fetch folders.");
  }
},

    async fetchLibraryFiles() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/agency-documents`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        this.files = response.data.documents.map((doc) => ({
          id: doc.id,
          name: doc.original_file_name || "Unnamed Document",
          type: doc.original_file_name
            ? doc.original_file_name.split(".").pop().toUpperCase()
            : "UNKNOWN",
          uploadedOn: doc.created_at || new Date().toISOString(),
        }));
      } catch (error) {
        console.error("Error fetching library files:", error);
        alert("Failed to fetch library files.");
      }
    },
    async createNewFolder() {
  const folderName = prompt("Enter the name of the new folder:");
  if (!folderName) return;

  try {
    const accessToken = localStorage.getItem("access_token");
    const url = `${process.env.VUE_APP_API_BASE_URL}/create-folder`;

    const response = await axios.post(
      url,
      { name: folderName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    console.log("CreateFolder Response: ", response);
    this.folders.push({ id: response.data.folder.id, name: folderName, object_type: "folder" });
    //alert(`Folder "${folderName}" created successfully.`);
  } catch (error) {
    console.error("Error creating folder:", error);

    // Check if the response object exists
    if (error.response && error.response.data) {
      // Display a user-friendly error message from the server
      alert(`${error.response.data.message || error.message}`);
    } else {
      // Fallback for network errors or unexpected issues
      alert(`${error.message}`);
    }
  }
},

    onDragStart(file) {
      this.draggedFile = file;
      console.log("Drag started for:", file);
    },
    async onDrop(folder) {
      if (!this.draggedFile) return;

      console.log(`File "${this.draggedFile.name}" dropped into folder "${folder.name}"`);

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/move-file`;

        await axios.post(
          url,
          {
            fileId: this.draggedFile.id,
            folderId: folder.id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        alert(`File "${this.draggedFile.name}" moved to folder "${folder.name}"`);
        this.draggedFile = null;

        this.fetchLibraryFiles();
      } catch (error) {
        console.error("Error moving file:", error);
        alert("Failed to move file.");
      }
    },
    onDragEnter(event) {
      const folderElement = event.target.closest(".folder-card");
      folderElement.classList.add("drag-over");
    },
    onDragLeave(event) {
      const folderElement = event.target.closest(".folder-card");
      folderElement.classList.remove("drag-over");
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    previewFile(file) {
  const token = localStorage.getItem("access_token");
  let url = "";
  if (file.is_shared) {
    url = `${process.env.VUE_APP_API_BASE_URL}/preview-shared/${file.id}`;
  } else {
    url = `${process.env.VUE_APP_API_BASE_URL}/preview-document/${file.id}`;
  }
  
  // Create a form element
  const form = document.createElement("form");
  form.method = "POST";
  form.action = url;
  form.target = "_blank"; // open in a new window or tab
  
  // Create a hidden input for the token
  const tokenInput = document.createElement("input");
  tokenInput.type = "hidden";
  tokenInput.name = "token"; // Your API should read the token from the request body
  tokenInput.value = token;
  form.appendChild(tokenInput);
  
  // Append the form to the document, submit it, then remove it
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
},

    getFileTypeIcon(type) {
      switch (type) {
        case "PDF":
          return "fa fa-file-pdf";
        case "DOCX":
        case "DOC":
          return "fa fa-file-word";
        case "XLSX":
        case "XLS":
          return "fa fa-file-excel";
        default:
          return "fa fa-file";
      }
    },
    async removeFileFromFolder(file) {
  if (!confirm(`Are you sure you want to remove ${file.name} from this folder?`)) return;

  if (file.is_shared) {
    return;
  }
  try {
    const accessToken = localStorage.getItem("access_token");
    const url = `${process.env.VUE_APP_API_BASE_URL}/folders/${this.currentFolder.id}/remove-file`;

    await axios.post(
      url,
      { fileId: file.id }, // Send file ID to remove from folder
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Remove the file from the folder view
    this.filesInFolder = this.filesInFolder.filter((f) => f.id !== file.id);

    //alert("File removed from folder successfully.");
  } catch (error) {
    console.error("Error removing file from folder:", error);
    alert("Failed to remove file from folder.");
  }
},

async deleteFolder(folder) {
      if (!confirm(`Are you sure you want to delete ${folder.name}?\nThis will unassign all files from it.`)) return;

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/delete-folder/${folder.id}`;

        await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        this.folders = this.folders.filter((f) => f.id !== folder.id);
        //alert("Folder deleted successfully.");
      } catch (error) {
        console.error("Error deleting folder:", error);
        alert("Failed to delete folder.");
      }
    },

    async removeFile(file) {
      if (!confirm(`Are you sure you want to delete ${file.name}?\nThis will delete it from all folders.`)) return;

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/delete-document-root/${file.id}`;

        await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        this.files = this.files.filter((f) => f.id !== file.id);
        //alert("File deleted successfully.");
      } catch (error) {
        console.error("Error deleting file:", error);
        alert("Failed to delete file.");
      }
    },
    handleOutsideClick(event) {
      if (!event.target.closest(".dropdown")) {
        this.activeDropdown = null;
      }
    },
    toggleDropdown(fileId) {
  this.activeDropdown = fileId;

  this.$nextTick(() => {
    const dropdown = document.querySelector('.dropdown-menu2');
    const button = document.querySelector(`.dropdown-toggle2[data-id="${fileId}"]`);
    if (!dropdown || !button) return;

    const buttonRect = button.getBoundingClientRect();
    const dropdownRect = dropdown.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let top = buttonRect.bottom; // Default below the button
    let left = buttonRect.left; // Default aligned with the button

    // Check vertical overflow
    if (buttonRect.bottom + dropdownRect.height > viewportHeight) {
      top = buttonRect.top - dropdownRect.height; // Move above the button
    }

    // Check horizontal overflow
    if (buttonRect.left + dropdownRect.width > viewportWidth) {
      left = viewportWidth - dropdownRect.width - 10; // Align to viewport edge
    }

    // Apply the adjusted position
    dropdown.style.position = 'fixed';
    dropdown.style.top = `${top}px`;
    dropdown.style.left = `${left}px`;
  });
},




  },
  mounted() {
    this.fetchLibraryFiles();
    this.fetchFolders();
    document.addEventListener("click", this.handleOutsideClick);
    document.addEventListener("click", this.hideContextMenu);

  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
    document.removeEventListener("click", this.hideContextMenu);

  },

};
</script>

<style scoped>
.context-menu {
  position: fixed; /* Position relative to the viewport */
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  padding: 10px;
  min-width: 150px;
}


.context-menu-item {
  display: block;
  width: 100%;
  padding: 8px 10px;
  text-align: left;
  font-size: 0.9rem;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease;
}
.context-menu-item:hover {
  background: #f1f1f1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top */
}

.modal-container {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto; /* Enable scrolling for content overflow */
  padding: 20px;
  position: relative; /* For proper positioning of close button */
  animation: fadeIn 0.3s ease-in-out; /* Optional: smooth modal entry */
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-modal-button:hover {
  color: #007bff;
}

.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333333;
}


.file-card {
  background: #f9f9f9;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 15px;
  width: 200px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.file-card:hover {
  transform: scale(1.05); /* Subtle zoom on hover */
}

.file-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}

.file-icon {
  font-size: 2rem;
  color: #333333;
}

.file-details {
  margin-bottom: 10px;
}

.file-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.file-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.file-actions button {
  padding: 5px 10px;
  font-size: 0.85rem;
  background: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-actions button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: Arial, sans-serif;
  gap: 20px;
  padding: 20px;
}

.library-index {
  width: 100%;
  background: #f0f4f8;
  padding: 20px;
  border-bottom: 2px solid #e0e0e0;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.index-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.file-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.recent-files {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.recent-file-card.redesigned {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 356px;
  height: 95px;
  border: 1px solid #ddd;
}

.file-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background: #f9f9f9;
  border-radius: 50%;
}

.file-icon {
  font-size: 1.8rem;
  color: #333;
}

.file-details {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0px;
  overflow: hidden;
}

.file-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 25px;
}

.file-date {
  font-size: 0.85rem;
  color: #777;
  margin-top: -15px;
}

.folder-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.folder-card.redesigned {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 355px;
  height: 90px;
  border: 1px solid #ddd;
}

.folder-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background: #fffbf0;
  border-radius: 50%;
}

.folder-icon {
  font-size: 1.8rem;
  color: #ffbf00;
}

.folder-details {
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.folder-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  padding-top: 15px;
}

.file-management {
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.toolbar.top-right {
  display: flex;
  gap: 10px;
  justify-content: flex-end; /* Aligns toolbar elements to the right */
  margin-top: -50px;
  align-items: center;
  margin-bottom: 10px; /* Adds some spacing from the elements below */
}

.button-container {
  display: flex;
  gap: 16px; /* Add spacing between buttons */
}

.new-folder-button,
.upload-file-button {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  background-color: #2333AD;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.new-folder-button:hover,
.upload-file-button:hover {
  background-color: #1C2890;
  transform: scale(1.02); /* Slight zoom effect */
}

.new-folder-button:active,
.upload-file-button:active {
  transform: scale(0.98); /* Slight press effect */
}

.button-icon {
  width: 20px;
  height: 20px;
}



.file-table.redesigned {
  width: 100%;
  border-collapse: collapse; /* Ensures there are no spacing issues */
  border-spacing: 0;
}

.file-table th {
  text-align: left;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
  background: #f9f9f9;
  border-bottom: 2px solid #ddd; /* Adds divider for columns */
}

.file-table td {
  padding: 10px;
  font-size: 0.85rem;
  color: #333;
  background: #fff;
  border-bottom: 1px solid #ddd; /* Divider for rows */
}

.actions-column {
  position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle2 {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}
.dropdown {
  position: relative;
}

.dropdown-menu {
  display: block !important; /* Ensure visibility */
  position: absolute; /* Position relative to the dropdown */
  top: 100%; /* Adjust position below the button */
  left: 0;
  z-index: 9999; /* Ensure it appears above other elements */
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 10px;
  pointer-events: auto; /* Ensure dropdown items are interactive */
  overflow: visible; /* Prevent clipping by parent elements */
}

.dropdown-menu2 {
  position: fixed;
  z-index: 9999;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  padding: 10px;
  min-width: 150px;
}


.dropdown-menu2.adjusted {
  transform: translate(0, 0);
}


.dropdown:hover .dropdown-menu {
  display: block; /* Keep dropdown open when hovered */
}

.dropdown-item {
  padding: 10px;
  font-size: 0.85rem;
  color: #333;
  cursor: pointer;
  z-index: 100;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid #ddd;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}


.footer {
  text-align: center;
  font-size: 0.85rem;
  color: #aaa;
  padding: 10px 0;
}

.search-bar {
  width: 250px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  outline: none;
}

.search-bar:focus {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}
</style>
