<template>
    <div class="faqs">
      <h2 class="faq-title">Frequently Asked Questions</h2>
      <div 
        v-for="(faq, index) in faqs" 
        :key="index" 
        class="faq-item"
      >
        <button 
          class="faq-question" 
          @click="toggleAnswer(index)"
        >
          {{ faq.question }}
          <span class="arrow" :class="{ expanded: activeIndex === index }">▼</span>
        </button>
        <div 
          class="faq-answer"
          v-if="activeIndex === index"
        >
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FAQs",
    data() {
      return {
        activeIndex: null, // Tracks the currently active question
        faqs: [
          {
            question: "How do I reset my Password?",
            answer: "On the login page use the Forgot Password link to send a password reset link to your email address."
          },
          {
            question: "What is 9 Bullets to bind-it",
            answer: "9 Bullets to bind-it is our email marketing automation tool, it allows producers to send automated emails to prospects for both new accounts and renewals."
          },
          {
            question: "Getting Started?",
            answer: "You can checkout our comprehensive video library for overviews & tutorials for each feature, it labeled Tutorial Videos on the navbar."
          }
        ]
      };
    },
    methods: {
      toggleAnswer(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      }
    }
  };
  </script>
  
  <style scoped>
  .faqs {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .faq-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .faq-item {
    border-bottom: 1px solid #ccc;
    padding: 1rem 0;
  }
  
  .faq-question {
    width: 100%;
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #007bff;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question:hover {
    color: #0056b3;
  }
  
  .arrow {
    transition: transform 0.3s ease;
  }
  
  .arrow.expanded {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    padding: 0.5rem 0;
    color: #555;
    font-size: 1rem;
    line-height: 1.5;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  