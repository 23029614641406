<template>
  <main v-if="!loading" class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4">
                <h5>Complete Registration</h5>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="submitForm">
                  <argon-input
                    id="name"
                    :value="form.name"
                    type="text"
                    placeholder="Name"
                    aria-label="Name"
                    readonly
                  />
                  <argon-input
                    id="email"
                    :value="form.email"
                    type="email"
                    placeholder="Email"
                    aria-label="Email"
                    readonly
                  />
                  <argon-input
                    id="password"
                    ref="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    aria-label="Password"
                    @input="form.password = $event.target.value"
                  />
                  <argon-input
                    id="password_confirm"
                    ref="password_confirm"
                    type="password"
                    name="password_confirm"
                    placeholder="Confirm Password"
                    aria-label="Confirm Password"
                    @input="form.passwordConfirm = $event.target.value"
                  />
                  <!-- Password validation error message -->
                  <div v-if="passwordError" class="text-danger text-center mb-3">
                    Passwords do not match.
                  </div>
                  <argon-checkbox
                    id="flexCheckDefault"
                    v-model="form.agreeTerms"
                    name="flexCheckDefault"
                  >
                    <label class="form-check-label" for="flexCheckDefault">
                      I agree to the
                      <a href="https://www.bizassure.com/terms-and-conditions/" target="_blank" class="text-dark font-weight-bolder">
                        Terms and Conditions
                      </a>
                    </label>
                  </argon-checkbox>
                  <div v-if="tosError" class="text-danger text-center">
                    You must accept the Terms and Conditions.
                  </div>
                  <div class="text-center">
                    <argon-button
                      type="submit"
                      full-width
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                    >
                      Sign up
                    </argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import { fetchInviteDetails, completeInviteSignup, isInviteTokenUsed } from '@/services/signup.service'; // Import the service
import authService from  '@/services/auth.service';

export default {
  name: "AgencySignup",
  components: {
    ArgonInput,
    ArgonCheckbox,
    ArgonButton,
  },
  data() {
    return {
      token: null,
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        agreeTerms: false,
      },
      tosError: false, // Add a data property to track the ToS error state
      passwordError: false, // Add a data property to track password matching
      loading: true
    };
  },
  async created() {
    this.token = this.$route.params.token;
    console.log('Token:', this.token); // Ensure the token is being logged

    // Step 1: Check if the token has already been used
    try {
      const { used } = await isInviteTokenUsed(this.token);
      if (used) {
        // Redirect to base URL if already used
        window.location.href = process.env.VUE_APP_BASE_URL;
        return;
      }
    } catch (err) {
      console.error('Error checking if token is used:', err);
      // Optional: redirect anyway on failure
      window.location.href = process.env.VUE_APP_BASE_URL;
      return;
    }

    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    await this.loadInviteDetails(); // Fetch and log the invite details
    this.loading = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    async loadInviteDetails() {
      try {
        const data = await fetchInviteDetails(this.token);
        console.log('Invite Details:', data); // Log the invite details to the console
        this.form.name = data.realName || ''; // Ensure that the form fields are updated
        this.form.email = data.email || '';
      } catch (error) {
        console.error('Error loading invite details:', error);
      }
    },

    async checkInviteUsed() {
      try {
        const data = await isInviteTokenUsed(this.token);
        console.log('is_used:', data); // Log the invite details to the console
        return data.is_used;
      } catch (error) {
        console.error('Error checking invite used: ', error);
      }
    },
    validatePasswords() {
      // Check if passwords match
      if (this.form.password !== this.form.passwordConfirm) {
        this.passwordError = true;
        return false;
      }
      
      this.passwordError = false;
      return true;
    },
    async submitForm() {
      // First check if passwords match
      if (!this.validatePasswords()) {
        return;
      }
      
      // Then check if terms are agreed to
      if (!this.form.agreeTerms) {
        this.tosError = true;
        return;
      }
      
      this.tosError = false;
      
      try {
        const signupResponse = await completeInviteSignup(this.token, this.form.password);
        console.log('Signup successful:', signupResponse);

        // Automatically log in the user
        const loginResponse = await authService.login({
          email: this.form.email,
          password: this.form.password,
        });

        
        console.log('Login successful:', loginResponse);
        
        if (loginResponse.access_token) {
          console.log('Login successful:', loginResponse);


        }

      } catch (error) {
        console.error('Error completing signup:', error);
        if (error.response && error.response.data) {
          // Extract and display the error message from the response
          this.errorMessage = error.response.data.error || 'An error occurred during signup. Please try again.';
        } else {
          // Generic error message
          this.errorMessage = 'An error occurred during signup. Please try again.';
        }
        alert(this.errorMessage); // Display the error message in an alert box
      }
    },
  },
};
</script>