<template>
  <div v-if="isOpen" class="modal-wrapper">
    <!-- Background overlay -->
    <div class="background-overlay"></div>

    <!-- Modal container -->
    <div class="modal-container">
      <h2 class="title">Attached Documents</h2>

      <!-- Documents List -->
      <div v-if="documents.length" class="documents-list">
        <ul class="documents-list-ul">
          <li v-for="(doc, index) in documents" :key="index" class="document-item">
            <span>{{ doc.name }} ({{ doc.type }})</span>
            <span class="document-actions">
              <i
                class="fas fa-eye preview-icon"
                title="Preview Document"
                @click="previewDocument(doc)"
              ></i>
              <i
                class="fas fa-times delete-icon"
                title="Remove Document"
                @click="deleteDocument(index)"
              ></i>
            </span>
          </li>
        </ul>
      </div>
      <div v-else class="no-documents">
        <p>No documents added.</p>
      </div>

      <!-- Library Section -->
      <h3 class="library-title">Library</h3>
      <div class="form-row">
        <div class="form-group">
          <label for="libraryDropdown" class="form-label">Select a Document</label>
          <select id="libraryDropdown" v-model="selectedLibraryDoc" class="form-input">
            <option value="">Select a document...</option>
            <option
              v-for="(libDoc, index) in libraryDocuments"
              :key="index"
              :value="libDoc.id"
            >
              {{ libDoc.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="documentType" class="form-label">Select Type</label>
          <select id="documentType" v-model="selectedType" class="form-input">
            <option value="">Select...</option>
            <option value="front">Front</option>
            <option value="back">Back</option>
          </select>
        </div>
      </div>



      <!-- File Upload Form -->
      <h3 class="upload-title">Upload Document</h3>
      <div class="form-row">
        <div class="form-group">
          <input
            type="file"
            id="documentFile"
            ref="fileInput"
            @change="autoUploadFile"
            class="form-input"
          />
        </div>
      </div>

      <!-- Buttons Container -->
      <div class="button-container">
        <button type="button" @click="closeModal" class="cancel-button">
          Close
        </button>
        <button
        type="button"
        class="add-button"
        @click="addDocument"
        :disabled="!selectedLibraryDoc || !selectedType"
      >
        Add
      </button>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    accountId: {
      type: Number,
      required: true, // Make sure the account ID is passed in
    },
  },
  data() {
    return {
      documents: [],
      libraryDocuments: [],
      selectedFile: null,
      selectedLibraryDoc: null,
      selectedType: '',
    };
  },
  computed: {
    canSubmit() {
      return this.selectedFile && this.selectedType;
    },
    selectedLibraryDocObject() {
      return this.libraryDocuments.find(doc => doc.id === this.selectedLibraryDoc);
    }
  },
  methods: {
    async fetchDocuments() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/session/${this.accountId}/documents`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        this.documents = response.data.document_pivots.map((doc) => ({
          id: doc.id, // Pivot ID
          name: doc.name, // Document name
          type: doc.type, // Document type (front/back)
        }));

        console.log("Fetched documents:", this.documents);
      } catch (error) {
        console.error("Error fetching documents:", error);
        this.documents = []; // Clear documents on error
        alert("Failed to fetch documents.");
      }
    },
    async fetchLibraryDocuments() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/agency-documents`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log("returned documents: ", response.data.documents);
        console.log("returned sharedDocuments: ", response.data.sharedDocuments);

        // Map regular documents (UploadedDocument)
        const regularDocs = response.data.documents.map((doc) => ({
          id: doc.id, // Document ID
          name: doc.original_file_name, // Original file name
          objectType: 'document',
        }));

        // Map shared documents (SalesObject) that are whitepapers and shared
        const sharedDocs = response.data.sharedDocuments.map((doc) => ({
          id: doc.id, // Shared SalesObject ID
          name: doc.name, // Name from SalesObject
          objectType: 'shared', // Flag for UI purposes
        }));

        // Combine both arrays into one
        this.libraryDocuments = [...regularDocs, ...sharedDocs];

        console.log("Fetched library documents:", this.libraryDocuments);
      } catch (error) {
        console.error("Error fetching library documents:", error);
        this.libraryDocuments = []; // Clear library on error
        alert("Failed to fetch library documents.");
      }
    },

    async autoUploadFile(event) {
      const files = event.target.files;
      this.selectedFile = files && files[0] ? files[0] : null;

      if (!this.selectedFile) return;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('type', this.selectedType || 'front'); // Default to 'front' if not selected

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/upload-document`;

        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log("Document uploaded successfully:", response.data);
        await this.fetchDocuments(); // Refresh documents list
        await this.fetchLibraryDocuments(); // Refresh library documents
        this.selectedFile = null; // Reset file input
      } catch (error) {
        console.error("Error uploading document:", error);
        const errorMessage = error.response
          ? `Status: ${error.response.status} - ${error.response.data.message || error.message}`
          : error.message;
        alert(`Error uploading document: ${errorMessage}`);
      }
    },
    async addDocument() {
      const selectedDoc = this.selectedLibraryDocObject;
      
      console.log("Selected Document:", selectedDoc);

      if (!this.selectedLibraryDoc || !this.selectedType) return;

      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/session/${this.accountId}/add-document`;
        console.log(url);
        console.log(this.selectedLibraryDoc);
        const response = await axios.post(
          url,
          {
            document_id: this.selectedLibraryDoc,
            type: this.selectedType,
            objectType: selectedDoc.objectType,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log("Document added to session successfully:", response.data);
        await this.fetchDocuments(); // Refresh documents list
        this.selectedLibraryDoc = null; // Reset selection
        this.selectedType = ''; // Reset type
      } catch (error) {
        console.error("Error adding document to session:", error);
        alert("Failed to add document to session.");
      }
    },
    async deleteDocument(index) {
      const doc = this.documents[index];
      try {
        const accessToken = localStorage.getItem("access_token");
        const url = `${process.env.VUE_APP_API_BASE_URL}/delete-document/${doc.id}`;

        await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        this.documents.splice(index, 1);
        console.log("Document deleted successfully");
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Failed to delete document.");
      }
    },
    previewDocument(doc) {
      // Logic for previewing a document
      alert(`Previewing: ${doc.name}`);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {
    this.fetchDocuments(); // Fetch attached documents
    this.fetchLibraryDocuments(); // Fetch library documents
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 2049;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 50vw;
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 2051;
  position: relative;
  border: 2px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.documents-list-ul {
  list-style: none;
  padding: 0;
}

.document-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  gap: 4%;
}

.document-actions {
  display: flex;
  gap: 10px;
}

.preview-icon,
.delete-icon {
  cursor: pointer;
  font-size: 16px;
}

.preview-icon {
  color: #007bff;
}

.delete-icon {
  color: #dc3545;
}

.no-documents {
  margin-bottom: 20px;
  color: #888;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  width: 48%;
}

.form-label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-button,
.save-button,
.add-button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 50px;
}

.cancel-button {
  background-color: #ccc;
  color: white;
  margin-right: 10px;
}

.add-button {
  background-color: #28a745;
  color: white;
}
</style>
