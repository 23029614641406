<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Top Statistics Section -->
      <div class="col-lg-8 col-12">
        <div class="row">
          <!-- Estimated Earnings Card -->
          <div class="col-lg-4 col-12"  @click="handleCardClick">
            <statistics-card
              title="Estimated Earnings"
              :count="`$${formatNumber(estimatedEarnings)}`"
              :badge="{ color: '', label: '' }"
              :action="{ label: 'Edit Details' }"
              :onClick="openModal"
            />
          </div>
          <!-- Premium and Liability Cards -->
          <div class="mt-4 col-lg-4 col-md-6 col-12 mt-lg-0">
            <mini-statistics-card-svg
              title="Annual Premium"
              :value="`$${formatNumber(annualPremium)}`"
              :icon="{
                svg: calenderSvgIcon
              }"
            />

            <mini-statistics-card-svg
              title="Gap Liability"
              :value="`$${formatNumber(gapLiability)}`"
              :icon="{
                svg: gapSvgIcon
              }"
            />
          </div>
          <!-- Consulting Savings and Probability Cards -->
          <div class="mt-4 col-lg-4 col-md-6 col-12 mt-lg-0">
            <mini-statistics-card-svg
              title="Consulting Savings"
              :value="`$${formatNumber(consultingSavings)}`"
              :icon="{
                svg: consultingSvgIcon
              }"
            />

            <mini-statistics-card-svg
              title="Probability to Close"
              :value="`${formatPercent(probabilityToClose)}`"
              :icon="{
                svg: probabilityToCloseSvgIcon
              }"
            />
          </div>
        </div>
      </div>


    </div>

    <!-- Campaign Management and View Reports Section -->
    <div class="row mt-4">
      <!-- Campaign Management Card -->
      <div class="col-lg-8 col-md-12 mb-4">
        <div class="card flex-grow-1" style="margin-top:10px;" @click="handleCardClick">
          <announcement-card
            description="9-Bullets to Bind-it is our flagship campaign automation tool. It is designed to automate your campaigns and client correspondence."
            :by="{
              image: image,
              name: 'Account Management System',
              date: '2h ago',
            }"
            :badge="{ color: 'info', label: 'Book Automation' }"
            :value="{
              label: 'Tasks',
              count: '5',
              unit: 'remaining',
            }"
            :action="{
              route: `#`,
              label: '9-Bullets to Bind-it',
            }"
            :summary="{
              campaignType: 'Standard Campaign',
              progress: 0,
              expirationDate: formattedDate,
            }"
          />
        </div>
      </div>

    </div>
  </div>
</template>





<script>
import StatisticsCard from "./components/StatisticsCard.vue";
import AnnouncementCard from "./components/AnnouncementCard.vue";
import MiniStatisticsCardSvg from "@/examples/Cards/MiniStatisticsCardSvg.vue";

import image from "@/assets/img/team-3.jpg";
import eventBus from "@/services/event-bus.service.js";
//import { nextTick } from 'vue';
import axios from "axios";


export default {
  name: "GeneralSearch",
  components: {
    AnnouncementCard,
    StatisticsCard,
    MiniStatisticsCardSvg
  },
  data() {
    return {
      calenderSvgIcon: `
<svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6098 3.0244V4.41464H7.8293C6.67818 4.41464 5.63272 4.88316 4.88059 5.63667C4.12847 6.39018 3.65857 7.43425 3.65857 8.58537V28.0488C3.65857 29.1999 4.12708 30.2454 4.88059 30.9975C5.63411 31.7496 6.67818 32.2195 7.8293 32.2195H27.2927C28.4438 32.2195 29.4893 31.751 30.2414 30.9975C30.9935 30.244 31.4634 29.1999 31.4634 28.0488V8.58537C31.4634 7.43425 30.9949 6.38879 30.2414 5.63667C29.4879 4.88455 28.4438 4.41464 27.2927 4.41464H24.5122V3.0244C24.5122 2.25698 23.8894 1.63416 23.122 1.63416C22.3546 1.63416 21.7317 2.25698 21.7317 3.0244V4.41464H13.3903V3.0244C13.3903 2.25698 12.7674 1.63416 12 1.63416C11.2326 1.63416 10.6098 2.25698 10.6098 3.0244ZM28.683 12.7561H6.43906V8.58537C6.43906 8.20167 6.59337 7.8555 6.8464 7.60247C7.09942 7.34945 7.44559 7.19513 7.8293 7.19513H10.6098V8.58537C10.6098 9.35279 11.2326 9.97562 12 9.97562C12.7674 9.97562 13.3903 9.35279 13.3903 8.58537V7.19513H21.7317V8.58537C21.7317 9.35279 22.3546 9.97562 23.122 9.97562C23.8894 9.97562 24.5122 9.35279 24.5122 8.58537V7.19513H27.2927C27.6764 7.19513 28.0226 7.34945 28.2756 7.60247C28.5286 7.8555 28.683 8.20167 28.683 8.58537V12.7561ZM6.43906 15.5366H28.683V28.0488C28.683 28.4325 28.5286 28.7787 28.2756 29.0317C28.0226 29.2847 27.6764 29.439 27.2927 29.439H7.8293C7.44559 29.439 7.09942 29.2847 6.8464 29.0317C6.59337 28.7787 6.43906 28.4325 6.43906 28.0488V15.5366Z" fill="#2333AD"/>
</svg>
      `,
      gapSvgIcon: `
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.3764 6.76389C16.4779 6.59289 16.6336 6.42745 16.8408 6.30233C17.1689 6.10353 17.5442 6.05487 17.8918 6.14106C18.2393 6.22726 18.5508 6.44136 18.7454 6.76389L30.5152 26.4122C30.6125 26.579 30.6806 26.7848 30.6931 27.0156C30.6723 27.5272 30.5277 27.8372 30.2997 28.0708C30.0495 28.3266 29.7061 28.4851 29.3363 28.4892L5.78971 28.4878C5.57839 28.4865 5.34344 28.4308 5.121 28.3015C4.78873 28.1097 4.5663 27.8025 4.47315 27.4577C4.38 27.1129 4.42032 26.7361 4.60105 26.4219L16.3764 6.76389ZM13.9908 5.33472L2.21539 24.9928C1.62871 26.009 1.51193 27.1476 1.7872 28.1764C2.06247 29.2052 2.73256 30.1339 3.73076 30.7095C4.37583 31.082 5.08764 31.2656 5.78137 31.2683H29.3363C30.5027 31.2558 31.5426 30.7762 32.2878 30.0143C33.0329 29.2525 33.4889 28.2028 33.4764 27.0517C33.4681 26.3065 33.2637 25.6003 32.912 25.0025L21.1311 5.33472C20.5305 4.34348 19.5879 3.69284 18.5535 3.43982C17.5192 3.18679 16.3834 3.32721 15.3991 3.92501C14.8027 4.28648 14.3216 4.78001 13.9921 5.33472H13.9908ZM16.1707 13.1952V18.7561C16.1707 19.5236 16.7935 20.1464 17.5609 20.1464C18.3283 20.1464 18.9511 19.5236 18.9511 18.7561V13.1952C18.9511 12.4277 18.3283 11.8049 17.5609 11.8049C16.7935 11.8049 16.1707 12.4277 16.1707 13.1952ZM17.5609 25.7074C18.3283 25.7074 18.9511 25.0845 18.9511 24.3171C18.9511 23.5497 18.3283 22.9269 17.5609 22.9269C16.7935 22.9269 16.1707 23.5497 16.1707 24.3171C16.1707 25.0845 16.7935 25.7074 17.5609 25.7074Z" fill="#2333AD"/>
</svg>
      `,
      consultingSvgIcon: `
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.3415 15.9268H13.2561C12.2954 15.9268 11.4293 15.539 10.7981 14.9092C10.167 14.2794 9.78049 13.4119 9.78049 12.4512C9.78049 11.4906 10.1684 10.6244 10.7981 9.99328C11.4279 9.36211 12.2954 8.97562 13.2561 8.97562H15.3415V15.9268ZM18.122 18.7073H20.2073C21.168 18.7073 22.0341 19.0952 22.6653 19.725C23.2964 20.3548 23.6829 21.2223 23.6829 22.1829C23.6829 23.1436 23.295 24.0097 22.6653 24.6409C22.0355 25.2721 21.168 25.6585 20.2073 25.6585H18.122V18.7073ZM23.6829 6.19513H18.122V2.0244C18.122 1.25698 17.4991 0.634155 16.7317 0.634155C15.9643 0.634155 15.3415 1.25698 15.3415 2.0244V6.19513H13.2561C11.5294 6.19513 9.96261 6.8972 8.83234 8.02747C7.70207 9.15774 7 10.7245 7 12.4512C7 14.1779 7.70207 15.7447 8.83234 16.875C9.96261 18.0053 11.5294 18.7073 13.2561 18.7073H15.3415V25.6585H8.39024C7.62283 25.6585 7 26.2814 7 27.0488C7 27.8162 7.62283 28.439 8.39024 28.439H15.3415V32.6098C15.3415 33.3772 15.9643 34 16.7317 34C17.4991 34 18.122 33.3772 18.122 32.6098V28.439H20.2073C21.934 28.439 23.5008 27.737 24.6311 26.6067C25.7613 25.4764 26.4634 23.9096 26.4634 22.1829C26.4634 20.4563 25.7613 18.8894 24.6311 17.7592C23.5008 16.6289 21.934 15.9268 20.2073 15.9268H18.122V8.97562H23.6829C24.4503 8.97562 25.0732 8.35279 25.0732 7.58537C25.0732 6.81796 24.4503 6.19513 23.6829 6.19513Z" fill="#2333AD"/>
</svg>
      `,
      probabilityToCloseSvgIcon: `
<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.4634 28.4878V14.5853C26.4634 13.8179 25.8406 13.1951 25.0732 13.1951C24.3058 13.1951 23.6829 13.8179 23.6829 14.5853V28.4878C23.6829 29.2552 24.3058 29.878 25.0732 29.878C25.8406 29.878 26.4634 29.2552 26.4634 28.4878ZM18.122 28.4878V6.24388C18.122 5.47647 17.4991 4.85364 16.7317 4.85364C15.9643 4.85364 15.3415 5.47647 15.3415 6.24388V28.4878C15.3415 29.2552 15.9643 29.878 16.7317 29.878C17.4991 29.878 18.122 29.2552 18.122 28.4878ZM9.78049 28.4878V20.1463C9.78049 19.3789 9.15766 18.7561 8.39024 18.7561C7.62283 18.7561 7 19.3789 7 20.1463V28.4878C7 29.2552 7.62283 29.878 8.39024 29.878C9.15766 29.878 9.78049 29.2552 9.78049 28.4878Z" fill="#2333AD"/>
</svg>

      `,
      contactEmail: '',
      numberOfEmployees: 0,
      xDate: '',
      image,
      estimatedEarnings: 0,
      annualPremium: 0,
      gapLiability: 0,
      consultingSavings: 0,
      probabilityToClose: 0,
    };
  },
  async mounted() {
    // Ensure listener registration before emitting
    await this.registerAndEmitSearch();
  },
  beforeUnmount() {
    // Hide search bar on unmount
    eventBus.emit("toggleHeaderSearch", false);
  },
  methods: {
    handleCardClick() {
      this.showSwal('warning-auto-close');

    //if (!this.accountId) {
    //  this.showSwal('warning-auto-close');
    //} else {
      // Navigate to the route or handle the normal action
      //this.$router.push(`/account/${this.accountId}`);
    //}
  },

  showSwal(type) {
    if (type === 'warning-auto-close') {
      let timerInterval;
      this.$swal({
        icon: 'warning',
        title: 'Account is not selected.',
        text: 'Please search an account first.',
        timer: 1200, // 2 seconds
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading();
          const b = this.$swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            if (b) b.textContent = this.$swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
        customClass: {
          confirmButton: 'btn bg-gradient-success',
        },
        buttonsStyling: false,
      });
    }
  },
    async registerAndEmitSearch() {
      const accessToken = localStorage.getItem("access_token");
            
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/get-accounts`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log("RESPONSE: ", response.data);

      // Wait until DOM and listeners are ready
      //await nextTick();

      setTimeout(() => {
        eventBus.emit("toggleHeaderSearch", true);
        eventBus.emit("updateProposalsList", response.data.sessions);

      }, 50); // Delay ensures listeners are registered
    },
    formatNumber(number) {
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 });
    },
    formatPercent(number) {
      return (number / 100).toLocaleString('en-US', {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
