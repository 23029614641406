<template>
  <div class="py-4 container-fluid" v-if="isLoaded">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <div v-if="currentStep === 1" class="container">
            <h2 class="heading-center">Enter Info</h2>
            <!-- My Name and My Agency -->
            <div class="form-group d-flex justify-content-center align-items-center">
              <span class="form-label mt-4 mx-2" style="font-size: 18px;">Name: <i>{{ formData.myName }}</i></span>
              <span class="form-label mt-4 mx-2" style="font-size: 18px;">Agency: <i>{{ formData.myAgency }}</i></span>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="incomeGoals" class="form-label label-fixed-width">My Increase Income Goals</label>
              </div>
              <div class="col-md-6">
                <input
                  id="incomeGoals"
                  type="text"
                  class="form-control"
                  v-model="formattedIncomeGoals"
                  @input="updateIncomeGoals"
                  placeholder="75,000"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="agencyIncomeShare" class="form-label label-fixed-width">My Share of Agency Income</label>
              </div>
              <div class="col-md-6">
                <input
                  id="agencyIncomeShare"
                  type="text"
                  class="form-control"
                  v-model="formattedAgencyIncomeShare"
                  @input="updateAgencyIncomeShare"
                  placeholder="40%"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="bookIncomeIncrease" class="form-label label-fixed-width">Book Income Increase Required</label>
              </div>
              <div class="col-md-6">
                <input
                  id="bookIncomeIncrease"
                  type="text"
                  class="form-control"
                  :value="formattedBookIncomeIncrease"
                  readonly
                  placeholder="187,500"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="currentBookValue" class="form-label label-fixed-width">My Current Book Agency Value</label>
              </div>
              <div class="col-md-6">
                <input
                  id="currentBookValue"
                  type="text"
                  class="form-control"
                  v-model="formattedCurrentBookValue"
                  @input="updateCurrentBookValue"
                  placeholder="200,000"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="renewalRateLargest" class="form-label label-fixed-width">My Expected Renewal Rate on Largest 20% of Accounts</label>
              </div>
              <div class="col-md-6">
                <input
                  id="renewalRateLargest"
                  type="text"
                  class="form-control"
                  v-model="formattedRenewalRateLargest"
                  @input="updateRenewalRateLargest"
                  placeholder="90%"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="renewalRateOther" class="form-label label-fixed-width">My Expected Renewal Rate on All Other Accounts</label>
              </div>
              <div class="col-md-6">
                <input
                  id="renewalRateOther"
                  type="text"
                  class="form-control"
                  v-model="formattedRenewalRateOther"
                  @input="updateRenewalRateOther"
                  placeholder="93%"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="incomePerNewAccount" class="form-label label-fixed-width">My Target Income per New Account</label>
              </div>
              <div class="col-md-6">
                <input
                  id="incomePerNewAccount"
                  type="text"
                  class="form-control"
                  v-model="formattedIncomePerNewAccount"
                  @input="updateIncomePerNewAccount"
                  placeholder="12,500"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="closingRatio" class="form-label label-fixed-width">My Target New Business Closing Ratio</label>
              </div>
              <div class="col-md-6">
                <input
                  id="closingRatio"
                  type="text"
                  class="form-control"
                  v-model="formattedClosingRatio"
                  @input="updateClosingRatio"
                  placeholder="30%"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="rateIncrease" class="form-label label-fixed-width">Expected Rate Increase on My Book</label>
              </div>
              <div class="col-md-6">
                <input
                  id="rateIncrease"
                  type="text"
                  class="form-control"
                  v-model="formattedRateIncrease"
                  @input="updateRateIncrease"
                  placeholder="5.0%"
                />
              </div>
            </div>
            <div class="mt-4 d-flex justify-content-between">
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-secondary me-2"
                @click="previousStep"
              >
                Back
              </button>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="nextStep"
              >
                Next
              </button>
            </div>
          </div>

          <div v-if="currentStep === 2" class="container">
            <h2 class="heading-center">Results</h2>
            <!-- My Name and My Agency -->
            <div class="form-group d-flex justify-content-center align-items-center">
              <span class="form-label mt-4 mx-2" style="font-size: 18px;">Name: <i>{{ formData.myName }}</i></span>
              <span class="form-label mt-4 mx-2" style="font-size: 18px;">Agency: <i>{{ formData.myAgency }}</i></span>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Large accounts renewed</div>
              <div class="col-md-6 text-right">{{ formattedLargeAccountsRenewed }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Small accounts renewed</div>
              <div class="col-md-6 text-right">{{ formattedSmallAccountsRenewed }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Book value after attrition</div>
              <div class="col-md-6 text-right">{{ formattedBookValueAfterAttrition }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Book value w/rate increase</div>
              <div class="col-md-6 text-right">{{ formattedBookValueWithRateIncrease }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Required book value to achieve my income goal</div>
              <div class="col-md-6 text-right">{{ formattedRequiredBookValue }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">New Production Required</div>
              <div class="col-md-6 text-right">{{ formattedNewProductionRequired }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Number of new accounts needed this year (rounded)</div>
              <div class="col-md-6 text-right">{{ formattedNewAccountsNeeded }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Total number of accounts I must present to this year (rounded)</div>
              <div class="col-md-6 text-right">{{ formattedTotalAccountsPresent }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">New presentations required p/week</div>
              <div class="col-md-6 text-right">{{ formattedPresentationsPerWeek }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">p/month</div>
              <div class="col-md-6 text-right">{{ formattedPresentationsPerMonth }}</div>
            </div>
            <div class="row mb-3 page2-item">
              <div class="col-md-6">Months to a $1million book</div>
              <div class="col-md-6 text-right">{{ formattedMonthsToMillion }}</div>
            </div>
            <div class="mt-4 d-flex justify-content-between">
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-secondary me-2"
                @click="previousStep"
              >
                Back
              </button>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="nextStep"
              >
                Next
              </button>
            </div>
          </div>

          <div v-if="currentStep === 3" class="container">
            <!-- Telemarketing and Referral Networking Sections Side by Side -->
            <div class="row">
              <!-- Telemarketing Section -->
              <div class="col-md-6">
                <h3 class="heading-center">Telemarketing</h3>
                <br>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <label for="dialToAppointmentRatio" class="form-label-marketing">Percentage of calls that lead to a qualified appointment:</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      id="dialToAppointmentRatio"
                      type="text"
                      class="form-control form-marketing"
                      v-model="formattedDialToAppointmentRatio"
                      @input="updateDialToAppointmentRatio"
                      placeholder="5%"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">Number of calls I need to make annually</div>
                  <div class="col-md-6 text-right">{{ formattedAnnualCalls }}</div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">Number of calls I need to make monthly</div>
                  <div class="col-md-6 text-right">{{ formattedMonthlyCalls }}</div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">Number of calls I need to make daily</div>
                  <div class="col-md-6 text-right">{{ formattedDailyCalls }}</div>
                </div>
              </div>
              <!-- Referral Networking Section -->
              <div class="col-md-6">
                <h3 class="heading-center">Referral Networking</h3>
                <br>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <label for="referralLeadsPerYear" class="form-label-marketing">Referral Source leads per year per referral source:</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      id="referralLeadsPerYear"
                      type="text"
                      class="form-control form-marketing"
                      v-model="formData.referralLeadsPerYear"
                      @input="updateReferralLeadsPerYear"
                      placeholder="12"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <label for="leadsQualified" class="form-label-marketing">Percentage of leads qualified:</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      id="leadsQualified"
                      type="text"
                      required
                      class="form-control form-marketing"
                      v-model="formData.leadsQualified"
                      @input="updateLeadsQualified"
                      placeholder="35%"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">Number of Referral Sources I will need based upon my income goal</div>
                  <div class="col-md-6 text-right">{{ formattedReferralSourcesNeeded }}</div>
                </div>
              </div>
            </div>

            <div class="mt-4 d-flex justify-content-between">
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-secondary me-2"
                @click="previousStep"
              >
                Back
              </button>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="nextStep"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>



<script>

import axios from 'axios';

export default {
  name: "BusinessPlan",
  props: {
    user_id: {
      type: Number,
      default: null
    }
  },
  async created() {
    await this.ensureDataReady();
    this.checkUserRoleAndId();
    await this.fetchAgentGoals();
  },
  data() {
    return {
      isLoaded: false,
      currentStep: 1,
      formData: {
        myName: "",
        myAgency: "",
        incomeGoals: "",
        agencyIncomeShare: "",
        bookIncomeIncrease: "",
        currentBookValue: "",
        renewalRateLargest: "",
        renewalRateOther: "",
        incomePerNewAccount: "",
        closingRatio: "",
        rateIncrease: "",
        telemarketingDials: "",
        referralLeads: "",
        leadsQualified: "",
        referralSources: "",
        dialToAppointmentRatio: "",
        referralLeadsPerYear: "",
      }
    };
  },
  watch: {
    'formData.incomeGoals': 'updateBookIncomeIncrease',
    'formData.agencyIncomeShare': 'updateBookIncomeIncrease'
  },
  computed: {
    formattedIncomeGoals() {
      return this.formatNumber(this.formData.incomeGoals);
    },
    formattedAgencyIncomeShare() {
      return this.formatPercent(this.formData.agencyIncomeShare);
    },
    formattedBookIncomeIncrease() {
      const value = Number(this.formData.bookIncomeIncrease);
      return !isNaN(value) && value !== 0
        ? this.formatNumber(value.toFixed(0))
        : null;
    },
    formattedCurrentBookValue() {
      return this.formatNumber(this.formData.currentBookValue);
    },
    formattedRenewalRateLargest() {
      return this.formatPercent(this.formData.renewalRateLargest);
    },
    formattedRenewalRateOther() {
      return this.formatPercent(this.formData.renewalRateOther);
    },
    formattedIncomePerNewAccount() {
      return this.formatNumber(this.formData.incomePerNewAccount);
    },
    formattedClosingRatio() {
      return this.formatPercent(this.formData.closingRatio);
    },
    formattedRateIncrease() {
      return this.formatPercent(this.formData.rateIncrease);
    },
    formattedLargeAccountsRenewed() {
      return this.formatNumber(this.calculateLargeAccountsRenewed());
    },
    formattedSmallAccountsRenewed() {
      return this.formatNumber(this.calculateSmallAccountsRenewed());
    },
    formattedBookValueAfterAttrition() {
      return this.formatNumber(this.calculateBookValueAfterAttrition());
    },
    formattedBookValueWithRateIncrease() {
      return this.formatNumber(this.calculateBookValueWithRateIncrease());
    },
    formattedRequiredBookValue() {
      return this.formatNumber(this.calculateRequiredBookValue().toFixed(0));
    },
    formattedNewProductionRequired() {
      return this.formatNumber(this.calculateNewProductionRequired().toFixed(0));
    },
    formattedNewAccountsNeeded() {
      return this.formatNumber(this.calculateNewAccountsNeeded());
    },
    formattedTotalAccountsPresent() {
      return this.formatNumber(this.calculateTotalAccountsPresent());
    },
    formattedPresentationsPerWeek() {
      return this.calculatePresentationsPerWeek().toFixed(2);
    },
    formattedPresentationsPerMonth() {
      return this.calculatePresentationsPerMonth().toFixed(1);
    },
    formattedMonthsToMillion() {
      return this.formatNumber(this.calculateMonthsToMillion());
    },
    formattedDialToAppointmentRatio() {
      return this.formatPercent(this.formData.dialToAppointmentRatio);
    },
    formattedAnnualCalls() {
      return this.formatNumber(this.calculateAnnualCalls());
    },
    formattedMonthlyCalls() {
      return this.formatNumber(this.calculateMonthlyCalls());
    },
    formattedDailyCalls() {
      return this.formatNumber(this.calculateDailyCalls());
    },
    formattedReferralSourcesNeeded() {
      return this.formatNumber(this.calculateReferralSourcesNeeded());
    }
  },
  methods: {
    
    async ensureDataReady() {
      if (!this.$store.getters['profile/me']) {
        try {
          await this.$store.dispatch('profile/me');
          console.log("User profile data fetched successfully.");
        } catch (error) {
          console.error("Failed to fetch user profile data:", error);
        }
      }
    },
    async fetchAgentGoals() {
  const user = this.$store.getters['profile/me'];
  let url;
  
  if (this.user_id) {
    if (user.role === 'global_admin' || user.role === 'agency_admin') {
      url = `${process.env.VUE_APP_API_BASE_URL}/producer/${this.user_id}/goals`;
    } else {
      console.error(`Unauthorized access: User with role ${user.role} tried to access goals for user_id ${this.user_id}`);
      return;
    }
  } else {
    url = `${process.env.VUE_APP_API_BASE_URL}/producer/goals`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }
    });

    const data = response.data.data;
    this.formData.incomeGoals = data.increase_income_goals;
    this.formData.agencyIncomeShare = data.share_of_agency_income_percent;
    this.formData.currentBookValue = data.current_book_agency_value;
    this.formData.renewalRateLargest = data.renewal_rate_largest_percent;
    this.formData.renewalRateOther = data.renewal_rate_other_percent;
    this.formData.incomePerNewAccount = data.income_per_new_account;
    this.formData.closingRatio = data.closing_ratio_percent;
    this.formData.rateIncrease = data.rate_increase_on_book_percent;
    this.formData.dialToAppointmentRatio = data.telemarketing_dials_to_appointment_ratio_percent;
    this.formData.referralLeadsPerYear = data.referral_leads_per_source;
    this.formData.leadsQualified = data.referral_number_leads_qualified;
    this.formData.myName = response.data.producer_name;
    this.formData.myAgency = response.data.agency_name;

    // Format the data for display
    this.formData.formattedIncomeGoals = this.formatNumber(data.increase_income_goals);
    this.formData.formattedAgencyIncomeShare = this.formatPercent(data.share_of_agency_income_percent);
    this.formData.formattedCurrentBookValue = this.formatNumber(data.current_book_agency_value);
    this.formData.formattedRenewalRateLargest = this.formatPercent(data.renewal_rate_largest_percent);
    this.formData.formattedRenewalRateOther = this.formatPercent(data.renewal_rate_other_percent);
    this.formData.formattedIncomePerNewAccount = this.formatNumber(data.income_per_new_account);
    this.formData.formattedClosingRatio = this.formatPercent(data.closing_ratio_percent);
    this.formData.formattedRateIncrease = this.formatPercent(data.rate_increase_on_book_percent);
    this.formData.formattedDialToAppointmentRatio = this.formatPercent(data.telemarketing_dials_to_appointment_ratio_percent);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn('Goals not found for this agent, but producer and agency names are available.');
      this.formData.myName = error.response.data.producer_name;
      this.formData.myAgency = error.response.data.agency_name;
    } else {
      console.error('Error fetching agent goals:', error.response.data);
      //alert('Failed to fetch agent goals. Please try again.');
    }
  }
},


async storeAgentGoals() {
  try {
    const user = this.$store.getters['profile/me'];
    let url;

    if (this.user_id) {
      if (user.role === 'global_admin' || user.role === 'agency_admin') {
        url = `${process.env.VUE_APP_API_BASE_URL}/producer/${this.user_id}/goals`;
      } else {
        console.error(`Unauthorized access: User with role ${user.role} tried to access goals for user_id ${this.user_id}`);
        alert('You are not authorized to perform this action.');
        return;
      }
    } else {
      url = `${process.env.VUE_APP_API_BASE_URL}/producer/goals`;
    }

    const payload = {
      increase_income_goals: parseInt(this.formData.incomeGoals),
      share_of_agency_income_percent: parseFloat(this.formData.agencyIncomeShare),
      current_book_agency_value: parseInt(this.formData.currentBookValue),
      renewal_rate_largest_percent: parseFloat(this.formData.renewalRateLargest),
      renewal_rate_other_percent: parseFloat(this.formData.renewalRateOther),
      income_per_new_account: parseInt(this.formData.incomePerNewAccount),
      closing_ratio_percent: parseFloat(this.formData.closingRatio),
      rate_increase_on_book_percent: parseFloat(this.formData.rateIncrease),
      telemarketing_dials_to_appointment_ratio_percent: parseFloat(this.formData.dialToAppointmentRatio),
      referral_leads_per_source: parseInt(this.formData.referralLeadsPerYear),
      referral_number_leads_qualified: parseInt(this.formData.leadsQualified)
    };

    const response = await axios.post(url, payload, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }
    });

    if (response.data) {
      alert('Goals saved successfully');
    }
  } catch (error) {
    console.error('Error storing agent goals:', error.response.data);
    alert('Failed to store agent goals. Please try again.');
  }
},
    checkUserRoleAndId() {
      const user = this.$store.getters['profile/me'];
      if (this.user_id) {
        console.log(`user_id provided: ${this.user_id}`);
        if (user.role !== 'global_admin' && user.role !== 'agency_admin') {
          console.log(`Unauthorized access: User with role ${user.role} tried to access goals for user_id ${this.user_id}`);
          return;
        }
      }
    },
    updateIncomeGoals(event) {
      this.updateField(event, 'incomeGoals', this.parseNumber, this.formatNumber);
    },
    updateAgencyIncomeShare(event) {
      this.updateField(event, 'agencyIncomeShare', this.parsePercent, this.formatPercent);
    },
    updateBookIncomeIncrease() {
      const incomeGoals = this.formData.incomeGoals;
      const agencyIncomeShare = this.formData.agencyIncomeShare;
      if (incomeGoals && agencyIncomeShare) {
        const bookIncomeIncrease = incomeGoals / (agencyIncomeShare / 100);
        this.formData.bookIncomeIncrease = bookIncomeIncrease;
      } else {
        this.formData.bookIncomeIncrease = "";
      }
    },
    updateCurrentBookValue(event) {
      this.updateField(event, 'currentBookValue', this.parseNumber, this.formatNumber);
    },
    updateRenewalRateLargest(event) {
      this.updateField(event, 'renewalRateLargest', this.parsePercent, this.formatPercent);
    },
    updateRenewalRateOther(event) {
      this.updateField(event, 'renewalRateOther', this.parsePercent, this.formatPercent);
    },
    updateIncomePerNewAccount(event) {
      this.updateField(event, 'incomePerNewAccount', this.parseNumber, this.formatNumber);
    },
    updateClosingRatio(event) {
      this.updateField(event, 'closingRatio', this.parsePercent, this.formatPercent);
    },
    updateRateIncrease(event) {
      this.updateField(event, 'rateIncrease', this.parsePercent, this.formatPercent);
    },
    updateDialToAppointmentRatio(event) {
      this.updateField(event, 'dialToAppointmentRatio', this.parsePercent, this.formatPercent);
    },
    updateReferralLeadsPerYear(event) {
      this.updateField(event, 'referralLeadsPerYear', this.parseNumber, this.formatNumber);
    },
    updateLeadsQualified(event) {
      this.updateField(event, 'leadsQualified', this.parsePercent, this.formatPercent);
    },
    updateField(event, field, parseFunc, formatFunc) {
      const input = event.target;
      const value = input.value;

      const selectionStart = input.selectionStart;
      const selectionEnd = input.selectionEnd;

      const parsedValue = parseFunc(value);
      this.formData[field] = parsedValue;
      const formattedValue = formatFunc(parsedValue);

      this.$nextTick(() => {
        input.value = formattedValue;
        const commasBefore = (value.slice(0, selectionStart).match(/,/g) || []).length;
        const commasAfter = (formattedValue.slice(0, selectionStart).match(/,/g) || []).length;
        const commaDiff = commasAfter - commasBefore;
        input.setSelectionRange(selectionStart + commaDiff, selectionEnd + commaDiff);
      });
    },
    parseNumber(value) {
      return parseFloat(value.replace(/,/g, ''));
    },
    parsePercent(value) {
      return parseFloat(value.replace(/,/g, '').replace(/%/g, ''));
    },
    formatNumber(value) {
      if (!value) return "";
      return new Intl.NumberFormat('en-US').format(value);
    },
    formatPercent(value) {
      if (!value) return "";
      return `${new Intl.NumberFormat('en-US').format(value)}%`;
    },
    async nextStep() {
      if (this.currentStep === 3) {
        await this.storeAgentGoals();

      }
      if (this.currentStep < 3) {
        this.currentStep++;
      }
      this.$nextTick();
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      } else {
        this.$router.go(-1); // Simulate pressing the back button
      }
    },
    calculateLargeAccountsRenewed() {
      return this.formData.currentBookValue * 0.80 * (this.formData.renewalRateLargest / 100);
    },
    calculateSmallAccountsRenewed() {
      return this.formData.currentBookValue * 0.20 * (this.formData.renewalRateOther / 100);
    },
    calculateBookValueAfterAttrition() {
      return this.calculateLargeAccountsRenewed() + this.calculateSmallAccountsRenewed();
    },
    calculateBookValueWithRateIncrease() {
      return this.calculateBookValueAfterAttrition() * (1 + this.formData.rateIncrease / 100);
    },
    calculateRequiredBookValue() {
      return this.formData.bookIncomeIncrease + this.formData.currentBookValue;
    },
    calculateMonthsToMillion() {
      return Math.ceil(1000000 / this.calculateRequiredBookValue() * 12);
    },
    calculateNewProductionRequired() {
      return this.calculateRequiredBookValue() - this.calculateBookValueWithRateIncrease();
    },
    calculateNewAccountsNeeded() {
      return Math.ceil(this.calculateNewProductionRequired() / this.formData.incomePerNewAccount);
    },
    calculateTotalAccountsPresent() {
      return Math.ceil(this.calculateNewAccountsNeeded() / (this.formData.closingRatio / 100));
    },
    calculatePresentationsPerWeek() {
      return this.calculateTotalAccountsPresent() / 50;
    },
    calculatePresentationsPerMonth() {
      return this.calculatePresentationsPerWeek() * 4;
    },
    calculateAnnualCalls() {
      return Math.ceil(this.calculateTotalAccountsPresent() / (this.formData.dialToAppointmentRatio / 100));
    },
    calculateMonthlyCalls() {
      return Math.ceil(this.calculateAnnualCalls() / 12);
    },
    calculateDailyCalls() {
      return Math.ceil(this.calculateMonthlyCalls() / 20);
    },
    calculateReferralSourcesNeeded() {
      return Math.ceil(this.calculateTotalAccountsPresent() / (this.formData.referralLeadsPerYear * (this.formData.leadsQualified / 100)));
    }
  },
  async mounted() {
    await this.ensureDataReady();
    this.checkUserRoleAndId();

    await this.fetchAgentGoals();
    //const user = this.$store.getters['profile/me'];
    //this.formData.myName = user.name || "John";
    //this.formData.myAgency = user.agency_name || "John Agency";
    this.isLoaded = true;
  }
};
</script>



<style scoped>
.heading-center {
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
}
.label-fixed-width {
  display: inline-block;
  min-width: 250px;
  max-width: 250px;
  font-size: 18px;
}
.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3%;
}
.form-group label {
  margin-right: 10px;
}
.form-label {
  margin-left: 80%;
}
.form-label-marketing {
  font-size: 16px;
  width: 100%;
}
.form-marketing {
  margin-left: 5% !important;
}
.form-control {
  margin-bottom: 25px;
  margin-left: 40%;
  width: 50%;
}
.page2-item {
  margin-left: 28%;
}
</style>
